<template>
  <div class="defalut-page">
    <div>
      <img src="https://wlmvp-cos.weiling.cn/website/static/images/default-env.svg" alt="default" />
      <div>
        请在微信中打开观看该直播
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({});
</script>
<style>
.defalut-page {
  height: 100%;
  text-align: center;
  color: #979797;
  font-size: 16px;
  line-height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.defalut-page img {
  width: 240px;
  max-width: 80%;
  margin: 0 auto;
}
</style>
