import HTTP from "@/utils/axios";
const api = process.env.VUE_APP_API;
const csApi = process.env.VUE_APP_API_CS;

export function polyvCustomLogIn(params: object, corp_id: string) {
  return HTTP.post(`${csApi}/polyv/client/customLogIn?corp_id=${corp_id}`, params);
}

export function xetClientLogin(params: object, corp_id?: string) {
  return HTTP.get(`${csApi}/xet/client/login`, {}, {}, { params });
}

export function xetClientGetCorpid(params: object) {
  return HTTP.get(`${csApi}/xet/client/get/corp_id`, {}, {}, { params });
}
