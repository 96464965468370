<template>
   <NotInWx v-if="!isInWeChat"/>
</template>
<script>
import Vue from "vue";
import { ref, defineComponent } from "@vue/composition-api";
import { getQueryVariable, isWeChat } from "@/utils/utils";
import { polyvCustomLogIn } from "@/api/polyv";
import { autoProtocol } from "@/utils/utils";
import { getSignData } from "@/api/common";
import NotInWx from "@/views/components/NotInWx.vue"

import useAuthcheck from "@/hooks/useAuthcheck";

export default defineComponent({
  components: {
    NotInWx
  },
  setup() {
    const isInWeChat = ref(isWeChat());

    const url = getQueryVariable("url");
    const channelId = getQueryVariable("channelId");
    const corp_id = getQueryVariable("corp_id");

    function logIn() {
      polyvCustomLogIn(
        {
          channel_id: channelId,
          url: url
        },
        corp_id
      ).then(res => {
        if (res.code === 200) {
          window.location = autoProtocol(res.data.url);
        }
      });
    }
    if (isInWeChat.value) {
      useAuthcheck().then(res => {
        if (res !== "uncheck") {
          getSignData().then(signData => {
            Vue.prototype.app_id = signData.app_id;
            logIn();
          });
        }
      });
    }

    return {
      isInWeChat
    };
  }
});
</script>
